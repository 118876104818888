:root {
  --text-xs: 12px;
  --text-sm: 14px;
  --text-md: 16px;
  --text-lg: 18px;
  --text-xl: 24px;
  --title: 80px;
  --subtitle: 60px;
  --title-sm: 38px;

  --primary-color: #6C1B99;
  --primary-color-alpha: #BEBDE3;
  --secondary-color: #ED60B7;
  --secondary-color-alpha: #D4C1D9;
  --tertiary-color: #D9D9D9;
  --footer-color: #8ebbc6;
  --yellow-color: #F2F0E4;
}

body {
  display: flex;
  justify-content: center;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}